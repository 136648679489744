import { useState, useEffect } from 'react';

import { useCustomerGetCustomerProfile } from '@dc/hooks';
import { isNextAuthEnabled } from '@dxp-auth';
import { useRouter } from '@dxp-next';
import { Placeholder } from '@sitecore/common';
import { MainMenuRendering } from '@sitecore/types/MainMenu';
import { LanguageSelectorRendering } from '@sitecore/types/manual/LanguageSelector';
import { MainMenuRightRendering } from '@sitecore/types/manual/NavigationBar';
import { SubMenuRendering } from '@sitecore/types/SubMenu';
import { DesktopHeader, MobileHeader, SideNav, StickyHeader } from '@sparky';
import { useMediaQuery } from '@sparky/hooks';

import LoginMenu from './LoginMenu';
import MainMenu from './MainMenu';
import ProfileMenu from './ProfileMenu';
import { ProfileMenuLink } from './ProfileMenuLink';
import SecondaryMenu from './SecondaryMenu';
import TopMenu from './TopMenu';
import usePlaceholderComponentContent from './usePlaceholderComponentContent';

/*
 * Sitecore returns the "MainNavigation" component with some dynamic
 * placeholders. The default <Placeholder> renders the components inside that
 * dynamic placeholder. However, this has a few downsides, since sub
 * components...
 *
 * - are isolated from this main header component, complicating shared state.
 * - are lazy-loaded, resulting in extra requests and delayed interactivity.
 * - require extra entries in `sitecoreComponents.ts` in every container (same
 *   for new components)
 *
 * As an optimized but less flexible alternative the
 * `usePlaceholderComponentContent` hook looks up the rendering so the sub
 * component can be rendered directly inside the main component without a
 * <Placeholder>. If we'll need this hook somewhere else as well we should
 * promote it to a lib.
 *
 * The <Logo> still has a dynamic placeholder for the different labels.
 */

/**
 * This hook retrieves the fields of either of the megamenu placeholders. It will return the fields of MegaMenuV2 if
 * the current site already uses it, otherwise it will return the fields of MegaMenu (V1).
 *
 * This is a temporary solution to support both versions of the MegaMenu until the old version is deprecated.
 */
const useMegaMenuPlaceholderFields = (): SubMenuRendering | null => {
  const megaMenuFieldsV2 = usePlaceholderComponentContent<SubMenuRendering>('jss-main-navigation-left', 'MegaMenuV2');
  const megaMenuFieldsV1 = usePlaceholderComponentContent<SubMenuRendering>('jss-main-navigation-left', 'MegaMenu');

  return megaMenuFieldsV2 || megaMenuFieldsV1;
};

const Desktop = () => {
  const router = useRouter();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

  const subItems = usePlaceholderComponentContent<SubMenuRendering>('jss-secondary-navigation', 'SubMenu')?.fields
    .items;
  const isOpenDomain = !subItems || subItems.length === 0;
  const isMegaMenu = !!useMegaMenuPlaceholderFields();
  const isLanguageListDropdown =
    usePlaceholderComponentContent<LanguageSelectorRendering>('jss-header-lang', 'LanguageSelector')?.params
      ?.RenderVariant === 'Dropdown';

  const handleRouteChange = () => setIsProfileMenuOpen(false);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [router]);

  return (
    <>
      <DesktopHeader>
        <TopMenu
          platform="desktop"
          languageSelector={isLanguageListDropdown ? <Placeholder name="jss-header-lang" /> : null}
        />
        <DesktopHeader.Main
          logoLink={<Placeholder name="jss-logo" />}
          languageSelector={!isLanguageListDropdown ? <Placeholder name="jss-header-lang" /> : null}
          profileLink={
            isNextAuthEnabled ? <ProfileMenuLink onOpen={() => setIsProfileMenuOpen(true)} /> : <LoginMenu />
          }
          isOpenDomain={isOpenDomain}>
          {isMegaMenu ? <Placeholder name="jss-main-navigation-left" /> : <MainMenu />}
        </DesktopHeader.Main>
        <SecondaryMenu />
      </DesktopHeader>
      <SideNav isDesktop={true} isOpen={isProfileMenuOpen} setIsOpen={setIsProfileMenuOpen}>
        <ProfileMenu />
      </SideNav>
    </>
  );
};

const Mobile = () => {
  const router = useRouter();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isCloseButtonVisible, setIsCloseButtonVisible] = useState(true);

  const megaMenuFields = useMegaMenuPlaceholderFields();
  const isMegaMenu = !!megaMenuFields;

  const mainMenuTitle = usePlaceholderComponentContent<MainMenuRendering>('jss-main-navigation-left', 'MainMenu')
    ?.fields?.title?.value;
  const megaMenuTitle = megaMenuFields?.fields?.title?.value;
  const hamburgerMenuLabel = isMegaMenu ? megaMenuTitle : mainMenuTitle;

  const handleRouteChange = () => {
    setIsProfileMenuOpen(false);
    setIsHamburgerMenuOpen(false);
  };

  const profileStateHandler = (state: boolean) => {
    if (isHamburgerMenuOpen) setIsHamburgerMenuOpen(false);
    setIsProfileMenuOpen(state);
  };

  const hamburgerStateHandler = (state: boolean) => {
    if (isProfileMenuOpen) setIsProfileMenuOpen(false);
    setIsHamburgerMenuOpen(state);
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [router]);

  const serviceLink = usePlaceholderComponentContent<MainMenuRightRendering>('jss-main-navigation-right', 'LoginMenu')
    ?.fields?.selfServiceLink?.value;
  const profileMenuLabel = serviceLink?.text;

  const isLoggedIn = !!useCustomerGetCustomerProfile().data;

  const isLanguageListDropdown =
    usePlaceholderComponentContent<LanguageSelectorRendering>('jss-header-lang', 'LanguageSelector')?.params
      ?.RenderVariant === 'Dropdown';

  return (
    <MobileHeader
      isHamburgerMenuOpen={isHamburgerMenuOpen}
      hamburgerMenuLabel={hamburgerMenuLabel}
      profileMenuLabel={profileMenuLabel}
      isProfileMenuOpen={isProfileMenuOpen}
      setHamburgerMenuOpen={hamburgerStateHandler}
      setProfileMenuOpen={profileStateHandler}
      logoLink={<Placeholder name="jss-logo" />}
      profileLink={serviceLink}
      isLoggedIn={isLoggedIn}
      languageSelector={isLanguageListDropdown ? <Placeholder name="jss-header-lang" /> : null}>
      <MobileHeader.HamburgerMenu isOpen={isHamburgerMenuOpen}>
        <TopMenu platform="mobile" />
        <MobileHeader.Main>
          {isMegaMenu ? <Placeholder name="jss-main-navigation-left" /> : <MainMenu />}
        </MobileHeader.Main>
        <MobileHeader.Bottom>
          <Placeholder name="jss-header-lang" />
        </MobileHeader.Bottom>
      </MobileHeader.HamburgerMenu>
      <SideNav
        isDesktop={false}
        isOpen={isProfileMenuOpen}
        setIsOpen={profileStateHandler}
        isCloseButtonVisible={isCloseButtonVisible}>
        <ProfileMenu setIsCloseButtonVisible={setIsCloseButtonVisible} />
      </SideNav>
    </MobileHeader>
  );
};

const MainNavigation = () => {
  const isDesktop = useMediaQuery('lg');

  return (
    <>
      <StickyHeader>{isDesktop ? <Desktop /> : <Mobile />}</StickyHeader>
      <Placeholder name="jss-main-navigation-breadcrumbs" />
    </>
  );
};

export default MainNavigation;
