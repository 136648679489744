import { useContent } from '@sitecore/common';
import type { ComponentRendering } from '@sitecore/common';

/**
 * Convenience hook to get the content of a child/sub component from one of its placeholders
 *
 * Example usage:
 *
 * ```tsx
 * const MyComponent = () => {
 *   const content = usePlaceholderComponentContent<MyComponentRendering>('jss-placeholder-name', 'ComponentName');
 *   if (!content) return null;
 *   const { fields } = content;
 *   return <ul>{fields.items.map(item => <li>{item.value}</li>)}</ul>;
 * };
 * ```
 *
 * @template T
 * @param {string} placeholderName
 * @param {string} componentName
 * @return {*}  {T | null}
 */
const usePlaceholderComponentContent = <T extends ComponentRendering>(
  placeholderName: string,
  componentName: string,
): T | null => {
  const { placeholders } = useContent();
  const placeholder = placeholders?.[placeholderName];
  if (!placeholder) return null;
  const component = placeholder.find(rendering => rendering.componentName === componentName);
  if (!component) return null;
  return component as T;
};

export default usePlaceholderComponentContent;
